<template>
  <section id="dashboard-analytics">
    <div class="mb-2">
      <b-dropdown
        :text="text"
        size="md"
        class="budget-dropdown"
        variant="outline-primary"
      >
        <b-dropdown-item
          size="sm"
          @click="change(text)"
        >
          {{ (text=='Today')?'Week':'Today' }}
        </b-dropdown-item>

      </b-dropdown>
    </div>
    <div>
      <b-row class="match-height">
        <b-col
          lg="4"
          sm="6"
        >
          <statistic-card-vertical
            icon="UserIcon"
            statistic="Welcome To AnyPay"
            :statistic-title="(currentUser)?currentUser.name:''"
            color="info"
          />
        </b-col>
        <b-col
          lg="8"
          sm="12"
        >
          <b-card
            no-body
            class="card-statistics"
          >
            <b-card-header>
              <b-card-title>Statistics</b-card-title>

              <!--  <b-dropdown
                  :text="text"
                  size="sm"
                  class="budget-dropdown"
                  variant="outline-primary"
                >
                  <b-dropdown-item
                    size="sm"
                    @click="change(text)"
                  >
                    {{(text=='Today')?'Week':'Today'}}
                  </b-dropdown-item>

                </b-dropdown>
                  <b-card-text class="mr-25 mb-0">
                 {{text}}
                </b-card-text> -->
            </b-card-header>
            <b-card-body class="statistics-body">
              <b-overlay
                variant="white"
                :show="showStatistics"
                spinner-variant="primary"
                blur="0"
                opacity=".28"
                rounded="sm"
              >
                <b-row>
                  <b-col
                    key="TrendingUpIcon"
                    md="3"
                    sm="6"
                    class="mb-2 mb-md-0"
                  >
                    <b-media no-body>
                      <b-media-aside

                        class="mr-2"
                      >
                        <b-avatar
                          size="48"
                          variant="light-primary"
                        >
                          <feather-icon
                            size="24"
                            icon="TrendingUpIcon"
                          />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">
                        <h4 class="font-weight-bolder mb-0">
                          {{ data.totalSale }}
                        </h4>
                        <b-card-text class="font-small-3 mb-0">
                          Sales
                        </b-card-text>
                      </b-media-body>
                    </b-media>
                  </b-col>
                  <b-col
                    key="BoxIcon"
                    md="3"
                    sm="6"
                    class="mb-2 mb-md-0"
                  >
                    <b-media no-body>
                      <b-media-aside

                        class="mr-2"
                      >
                        <b-avatar
                          size="48"
                          variant="light-danger"
                        >
                          <feather-icon
                            size="24"
                            icon="BoxIcon"
                          />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">
                        <h4 class="font-weight-bolder mb-0">
                          {{ data.totalOperator }}
                        </h4>
                        <b-card-text class="font-small-3 mb-0">
                          Operator
                        </b-card-text>
                      </b-media-body>
                    </b-media>
                  </b-col>
                  <b-col
                    key="DollarSignIcon"
                    md="3"
                    sm="6"
                    class="mb-2 mb-md-0"
                  >
                    <b-media no-body>
                      <b-media-aside

                        class="mr-2"
                      >
                        <b-avatar
                          size="48"
                          variant="light-success"
                        >
                          <feather-icon
                            size="24"
                            icon="DollarSignIcon"
                          />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">
                        <h4 class="font-weight-bolder mb-0">
                          {{ data.totalProfit }}
                        </h4>
                        <b-card-text class="font-small-3 mb-0">
                          Profit
                        </b-card-text>
                      </b-media-body>
                    </b-media>
                  </b-col>
                  <b-col
                    key="BriefcaseIcon"
                    md="3"
                    sm="6"
                    class="mb-2 mb-md-0"
                  >
                    <b-media no-body>
                      <b-media-aside

                        class="mr-2"
                      >
                        <b-avatar
                          size="48"
                          variant="light-success"
                        >
                          <feather-icon
                            size="24"
                            icon="BriefcaseIcon"
                          />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">
                        <h4 class="font-weight-bolder mb-0">
                          {{ data.walletBalance }}
                        </h4>
                        <b-card-text class="font-small-3 mb-0">
                          Balance
                        </b-card-text>
                      </b-media-body>
                    </b-media>
                  </b-col>
                </b-row>
              </b-overlay>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          xl="2"
          md="4"
          sm="6"
        >
          <b-overlay
            variant="white"
            :show="showTotal"
            spinner-variant="primary"
            blur="0"
            opacity=".28"
            rounded="sm"
          >
            <statistic-card-vertical
              icon="DatabaseIcon"
              :statistic="totalTrans"
              statistic-title="Total Transaction"
              color="info"
            />
          </b-overlay>
        </b-col>
        <b-col
          xl="2"
          md="4"
          sm="6"
        >
          <b-overlay
            variant="white"
            :show="showsuccess"
            spinner-variant="primary"
            blur="0"
            opacity=".28"
            rounded="sm"
          >
            <statistic-card-vertical
              icon="CheckIcon"
              :statistic="success"
              statistic-title="Success"
              color="success"
            />
          </b-overlay>
        </b-col>
        <b-col
          xl="2"
          md="4"
          sm="6"
        >
          <b-overlay
            variant="white"
            :show="showfailed"
            spinner-variant="primary"
            blur="0"
            opacity=".28"
            rounded="sm"
          >
            <statistic-card-vertical
              icon="AlertCircleIcon"
              :statistic="failed"
              statistic-title="Failed"
              color="danger"
            />
          </b-overlay>
        </b-col>
        <b-col
          xl="2"
          md="4"
          sm="6"
        >
          <b-overlay
            variant="white"
            :show="showpending"
            spinner-variant="primary"
            blur="0"
            opacity=".28"
            rounded="sm"
          >
            <statistic-card-vertical
              icon="ActivityIcon"
              :statistic="pending"
              statistic-title="Inprocess"
              color="warning"
            />
          </b-overlay>
        </b-col>
        <b-col
          xl="2"
          md="4"
          sm="6"
        >
          <statistic-card-vertical
            icon="LayersIcon"
            :statistic="totalPayout"
            statistic-title="Total Payout"
            color="success"
          />
        </b-col>
        <b-col
          xl="2"
          md="4"
          sm="6"
        >
          <statistic-card-vertical
            icon="BarChartIcon"
            :statistic="data.totalTransfer"
            statistic-title="Total Transfer"
            color="warning"
          />
        </b-col>
      </b-row>
      <b-row class="match-height">
        <b-col
          xl="6"
          md="6"
          sm="12"
        >

          <b-card
            no-body
            class="card-statistics"
          >
            <b-card-header>
              <b-card-title>Top 5 Products</b-card-title>

            </b-card-header>
            <b-card-body no-body>
              <b-overlay
                variant="white"
                :show="showLoading"
                spinner-variant="primary"
                blur="0"
                opacity=".28"
                rounded="sm"
              >
                <b-table-simple
                  hover
                  striped
                  bordered
                  responsive
                  class="rounded-bottom mb-0"
                >
                  <b-thead head-variant="light">
                    <b-tr>

                      <b-th>Product Name</b-th>
                      <b-th>Total Transaction</b-th>
                      <b-th>Total Amount</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <template v-if="topFiveOperator.length>0">
                      <b-tr
                        v-for="(tr, indextr) in topFiveOperator"
                        :key="indextr"
                      >
                        <b-td>
                          {{ tr.operator_name }}
                        </b-td>
                        <b-td>
                          {{ tr.transections_count }}
                        </b-td>

                        <b-td>{{ (tr.transections_sum)?tr.transections_sum +' MYR':'0.00  MYR' }}</b-td>

                      </b-tr>
                    </template>
                    <template v-else>
                      <b-tr rowspan="10">
                        <b-td
                          colspan="11"
                          class="text-center"
                        >
                          No data available
                        </b-td>
                      </b-tr>
                    </template>
                  </b-tbody>
                </b-table-simple>

              </b-overlay>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col
          xl="6"
          md="6"
          sm="12"
        >

          <b-card
            no-body
            class="card-statistics"
          >
            <b-card-header>
              <b-card-title>Last 5 Transactions</b-card-title>
            </b-card-header>
            <b-card-body no-body>
              <b-table-simple
                hover
                striped
                bordered
                responsive
                class="rounded-bottom mb-0"
              >
                <b-thead head-variant="light">
                  <b-tr>
                    <b-th>PRODUCT/TYPE</b-th>
                    <b-th>RELOAD NO/ACCOUNT NO</b-th>
                    <b-th>AMOUNT</b-th>
                    <b-th>Profit</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <template v-if="transections.length>0">
                    <b-tr
                      v-for="(tr, indextr) in transections"
                      :key="indextr"
                    >
                      <b-td>
                        {{ tr.operator.operator_name }}
                      </b-td>
                      <b-td>
                        {{ tr.recharge_tpr_num }}
                      </b-td>

                      <b-td>{{ tr.amount }} MYR</b-td>
                      <b-td>{{ tr.profit }} MYR</b-td>
                    </b-tr>
                    <b-card-text class="mr-25 mb-0 mt-1">
                      <a
                        href="javascript:void(0)"
                        @click="viewAll"
                      >
                        View all Transections
                      </a>
                    </b-card-text>
                  </template>
                  <template v-else>
                    <b-tr rowspan="10">
                      <b-td
                        colspan="11"
                        class="text-center"
                      >
                        No data available
                      </b-td>
                    </b-tr>
                  </template>
                </b-tbody>
              </b-table-simple>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <!--  <div v-else>
          <div class="text-center text-primary my-2">
                <b-spinner class="align-middle" />
                <strong>This will only take a moment. Please wait...</strong>
              </div>
        </div> -->
  </section>
</template>
<script>
import {
  BTableSimple,
  BCard,
  BRow,
  BCol,
  BAvatar,
  BCardHeader,
  BCardTitle,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardBody,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BOverlay,
  BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import { mapGetters } from 'vuex'
import DashboardService from '../services/dashboard.service'

export default {
  components: {
    BTableSimple,
    BCard,
    BRow,
    BCol,
    BAvatar,
    BCardHeader,
    BCardTitle,
    BCardText,
    StatisticCardVertical,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardBody,
    BThead,
    BTr,
    BTh,
    BTd,
    BOverlay,
    BTbody,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      data: {
        totalSale: '0.00',
        totalProfit: '0.00',
        totalTransfer: '0.00',
        walletBalance: '0.00',
        totalOperator: '0.00',
      },
      failed: 0,
      success: 0,
      pending: 0,
      totalPayout: 0,
      totalTrans: 0,
      transections: '',
      topFiveOperator: '',
      text: 'Today',
      showLoading: true,
      showTotal: true,
      showsuccess: true,
      showfailed: true,
      showpending: true,
      showStatistics: true,
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
    ]),
  },
  mounted() {
    this.dashboardInfo()
    this.getTotalTransection()
    this.getLastFiveTransection()
  },
  methods: {
    change(value) {
      this.showLoading = true
      this.showTotal = true
      this.showsuccess = true
      this.showfailed = true
      this.showpending = true
      this.text = (value == 'Today') ? 'Week' : 'Today'
      this.dashboardInfo({ type: this.text.toLowerCase() })
      this.getTopFiveOperator()
      this.getLastFiveTransection({ type: this.text.toLowerCase() })
      this.getTotalTransection()
    },
    dashboardInfo() {
      this.showStatistics = true
      DashboardService.dashboardInfo({ type: this.text.toLowerCase() }).then(
        response => {
          this.data = response
          this.showStatistics = false
        },
        error => {
          this.showStatistics = false
        },
      )
    },
    getPayoutTransferedTransection() {
      DashboardService.getTotalTransectionByStatus({ type: this.text.toLowerCase(), status: 'Payout Transfered' }).then(
        response => {
          this.totalPayout = response.total_trns
          this.getTopFiveOperator()
        },
        error => {
        },
      )
    },
    getPendingTransection() {
      DashboardService.getTotalTransectionByStatus({ type: this.text.toLowerCase(), status: 'Pending' }).then(
        response => {
          this.pending = response.total_trns
          this.showpending = false
          this.getPayoutTransferedTransection()
        },
        error => {
          this.showpending = false
        },
      )
    },
    getFailedTransection() {
      DashboardService.getTotalTransectionByStatus({ type: this.text.toLowerCase(), status: 'Failed' }).then(
        response => {
          this.failed = response.total_trns
          this.showfailed = false
          this.getPendingTransection()
        },
        error => {
          this.showfailed = false
        },
      )
    },
    getSuccessTransection() {
      DashboardService.getTotalTransectionByStatus({ type: this.text.toLowerCase(), status: 'Success' }).then(
        response => {
          this.success = response.total_trns
          this.showsuccess = false
          this.getFailedTransection()
        },
        error => {
          this.showsuccess = false
        },
      )
    },
    getTotalTransection() {
      DashboardService.getTotalTransectionByStatus({ type: this.text.toLowerCase() }).then(
        response => {
          this.totalTrans = response.total_trns
          this.showTotal = false
          this.getSuccessTransection()
        },
        error => {
          this.showTotal = false
        },
      )
    },
    getTopFiveOperator() {
      this.showLoading = true
      DashboardService.getTopFiveOperator({ type: this.text.toLowerCase() }).then(
        response => {
          this.topFiveOperator = response.topOperator
          this.showLoading = false
        },
        error => {},
      )
    },
    getLastFiveTransection() {
      DashboardService.getLastFiveTransection().then(
        response => {
          this.transections = response.fiveTransection
        },
        error => {},
      )
    },
    viewAll() {
      this.$router.push('/all/transactions')
    },
  },
}

</script>
